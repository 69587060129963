export function isotope(value) {


    // init Isotope
    let $grid = value;

// store filter for each group
    var filters = {};
    var mainfilter;

    $('.filter-container').on('click', '.button', function (event) {
        filter_products(event.currentTarget)
    });


    function filter_products(e) {

        var button = $(e);
        // get group key
        var buttonGroup = button.parents('.filter-button-group');
        var filterGroup = buttonGroup.attr('data-filter-group');
        if (button.hasClass('disabled')) {
            filters = [];
            $grid.isotope({filter: "*"});

            $('.is-checked').each(function (index, value) {
                $(value).removeClass('is-checked');
            });
            $('.filter-button').each(function (index, value) {
                $(value).removeClass('disabled');
            });
        }
        // set filter for group
        if (filters[filterGroup] == null) {
            filters[filterGroup] = [];
        }


        if (filters[filterGroup].includes(button.attr('data-filter'))) {
            var index = filters[filterGroup].indexOf(button.attr('data-filter'));    // <-- Not supported in <IE9
            if (index !== -1) {
                filters[filterGroup].splice(index, 1);
                $('.filter-button-group').find(button.attr('data-filter')).each(function (index, value) {
                    $(value).removeClass('is-checked');
                });
                //button.removeClass('is-checked');

                if (typeof filters[mainfilter] === 'undefined' || filters[mainfilter].length <= 0) {

                    $('.filter-button').each(function (index, value) {
                        if ($(value).parent().data('filter-group') !== mainfilter && typeof filters[mainfilter] !== 'undefined') {
                            $(value).removeClass("is-checked");
                            if (typeof filters[$(value).parent().data('filter-group')] !== 'undefined') {
                                if (filters[$(value).parent().data('filter-group')].length > 0) {
                                    filters[$(value).parent().data('filter-group')] = [];
                                }
                            }

                        }
                    });

                }

            }
        } else {
            $('.filter-button-group').find(button.attr('data-filter')).each(function (index, value) {
                //console.log("",value );
                $(value).addClass('is-checked');
            });
            //button.addClass('is-checked');
            filters[filterGroup].push(button.attr('data-filter'));
        }
        if (typeof filters[mainfilter] === 'undefined' || filters[mainfilter].length <= 0) {

            mainfilter = filterGroup;
        }
        //filters[ filterGroup ] = $button.attr('data-filter');
        // combine filters
        var filterValue = concatValues(filters, mainfilter);

        // set filter for Isotope
        if (filterValue === "") {
            filterValue = "*"
        }
        $grid.isotope({filter: filterValue});
    }

// flatten object by concatting values
    function concatValues(obj, mainFilter) {
        // disable_buttons();
        var mainArray = obj[mainFilter];
        var mainPlainProp;
        var value = '';
        if (Object.keys(obj).length < 2) {
            for (mainPlainProp in mainArray) {
                value += mainArray[mainPlainProp] + ", ";
            }

        } else {
            for (var prop in obj) {
                if (prop !== mainFilter) {

                    if (obj[prop].length === 0) {
                        for (mainPlainProp in mainArray) {
                            value += mainArray[mainPlainProp] + ", ";
                        }
                    } else {
                        for (var childProp in obj[prop]) {
                            for (var mainProp in mainArray) {
                                value += mainArray[mainProp] + obj[prop][childProp] + ", ";
                            }
                        }
                    }
                }
            }
        }
        return value.slice(0, -2);
    }

    function disable_buttons(categories) {
        var divs = $('.filter-button');
        divs.each(function (index, value) {
            if ($(value).parent().data('filter-group') !== mainfilter) {
                if ($.inArray($(value).data('filter').slice(1), categories) === -1) {
                    $(value).addClass("disabled");
                } else {
                    $(value).removeClass("disabled")
                }
            }
        });
    }

    $grid.on('arrangeComplete', function (event, filteredItems) {
        var categories = [];

        $.each(filteredItems, function (i, value) {
            categories = categories.concat($(value.element).data('filter-category').split(','));

        });

        var uniques = categories.filter(function (item, index) {
            return categories.indexOf(item) >= index;
        });
        disable_buttons(uniques);

    });


    function getHashFilter() {
        var hash = location.hash;
        // get filter=filterName
        var matches = location.hash.match(/filter=([^&]+)/i);
        var hashFilter = matches && matches[1];
        return hashFilter && decodeURIComponent(hashFilter);
    }

    var isIsotopeInit = false;

    function onHashchange() {
        var hashFilter = getHashFilter();
        if (!hashFilter && isIsotopeInit) {
            return;
        }
        isIsotopeInit = true;
        // filter isotope
        /*   $grid.isotope({
               filter: hashFilter
           });*/
        // set selected class on button
        if (hashFilter) {

            $('.filter-button-group').find('.is-checked').removeClass('is-checked');
            $('.filter-button-group').find('.disabled').removeClass('disabled');
            filters = [];
            filter_products($('.filter-button-group').find('[data-filter="' + hashFilter + '"]'));
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".filter-title").offset().top/* - 75*/
            }, 500);
            //mainfilter = $('.filter-button-group').find('[data-filter="' + hashFilter + '"]').parent().data('filter-group');
        }
    }

    $(window).on('hashchange', onHashchange);
    // trigger event handler to init Isotope
    onHashchange();
}
