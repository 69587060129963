"use strict";

/*global
 window, document, console, require, Foundation
 */
let $grid = $('#product-grid').isotope({
    itemSelector: '.filter-product'
});
let mobile_navigation_open = false;
import 'lib/foundation-modules';
import {isotope} from "./isotope_conf";
import {ajax_calls} from "./ajax-calls";
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', () => {
    $(document).foundation();
    $('.toggle-mobile-nav').click(function () {
        $('.site-header').toggleClass('mobile-nav-open');
        mobile_navigation_open = !mobile_navigation_open;
    });
    $('#nav-menu .submenu').click(function () {
        if(!Foundation.MediaQuery.atLeast('medium')){
            $('#example-menu').css('display','none');
        }

    });
    $(window).on('toggled.zf.responsiveToggle', function () {
    });
    $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {

        if (Foundation.MediaQuery.atLeast('medium') && oldSize === 'small') {
            $('.site-header').removeClass('mobile-nav-open');
        }
    });

    $('article').find('img.alignleft').each(function (index, value) {
        //console.log("",$(value));
        $(value).parent().addClass('align-left');
    });

    //test for touch events support and if not supported, attach .no-touch class to the HTML tag.
    if (!("ontouchstart" in document.documentElement)) {

        document.documentElement.className += " no-touch";
    }

    isotope($grid);
    ajax_calls();
    $('.is-accordion-submenu-item').click(function (e) {
        $('.site-header').toggleClass('mobile-nav-open');
        mobile_navigation_open = !mobile_navigation_open;
    });
    $('.filter-button .info-container').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        let reveal_element = $(e.currentTarget).data('open');
        $('#'+reveal_element).foundation('toggle');
    });
    $('.product-fragrance .info-container-mobile').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        let infoButton = $(e.currentTarget);
        let show_element =  $('#'+infoButton.data('mobileopen'));
        if(infoButton.hasClass("active-info")){
            infoButton.removeClass("active-info");
            show_element.removeClass("show-info");

        }else{
            $('.active-info').removeClass("active-info");
            $('.show-info').removeClass("show-info");
            infoButton.addClass("active-info");
            show_element.addClass("show-info");
        }


    });
    var lastScrollTop = 100;
    $(window).scroll(function(event){
        if(!mobile_navigation_open){
            var st = $(this).scrollTop();
            if (st > lastScrollTop && st > 75){
                $('.navigation-bar').addClass('hidden');
                $('.is-dropdown-submenu').addClass('hidden');
            } else {
                $('.navigation-bar').removeClass('hidden');
                $('.is-dropdown-submenu').removeClass('hidden');
            }
        }
        lastScrollTop = st;
    });

/*
// Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.navigation-bar').outerHeight();
console.log("",navbarHeight);
    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        console.log("scrollattu");
        var st = $(window).scrollTop();
console.log("",st);
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
           // $('header').removeClass('nav-down').addClass('nav-up');
            $('.navigation-bar').addClass('hidden');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('.navigation-bar').removeClass('hidden');
            }
        }

        lastScrollTop = st;
    }
*/



    //hero carusel confs
    const slider = $('#slick-slider');
    const slider_product = $('#slick-slider-product');
    if(slider_product.length === 0){
        slider.slick({
            autoplay: true,
            autoplaySpeed: slider.data('speed') * 1000,
            dots: false,
            arrows: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            rows: 0
        });
    }else{
        slider.slick({
            autoplay: true,
            autoplaySpeed: slider.data('speed') * 1000,
            dots: false,
            arrows: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            rows: 0,
            asNavFor: '#slick-slider-product'
        });
        slider_product.slick({
            autoplay: false,
            fade:true,
            dots: false,
            arrows: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            draggable: false,
            rows: 0,
        });
    }

});






